import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './css/ContentPage.css'; // Ensure this is correctly imported

import DownloadTable from './DownloadTable';

const documents = [
  {
    name: 'OMNI User Manual v1.0',
    description: 'The user manual of the OMNI device.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/OMNI_User_Manual_v1.0.pdf',
  },
  {
    name: 'Quick Installation Guide v1.0',
    description: 'The quick installation guide of OMNI device.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/OMNI_Portal_Quick_installation_guide.pdf',
  },
  {
    name: 'OMNI Installation/Calibration Manual v1.2',
    description: 'The manual for OMNI installation and calibration.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/OMNI_Installation_Calibration_Manual_v1.2.pdf',
  },
  {
    name: 'OMNI App Quick User Guide v1.0',
    description: 'The quick instruction of app features.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/OMNI_App_Quick_User_guide_v1.0.pdf',
  },
  {
    name: 'OMNI Cloud Quick User Guide v1.0',
    description: 'The quick instruction of cloud features.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/OMNI_Cloud_Quick_User_guide_v1.0.pdf',
  },
  {
    name: 'OMNI Software MAI.ES.23.1224.1',
    description: 'The software updating file.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/SigmastarUpgradeSD.bin',
  },
  // ... more documents
];

function OmniOverviewPage({ user }) {
  useEffect(() => {
    return () => {};
  }, [user]);

  return (
    <div className="page-body full-width flush docs-page">
      <div className="documentPageContainer">
        <div className="centerContent">
          <div className="desc-container">
            <h1>OMNI Fleet Management Solution</h1>
            <h2>
              Revolutionize Your Fleet Management with Our Innovative In-Car
              Device(OMNI) and Integrated System
            </h2>
            <p>
              Enhance your fleet operations with our advanced in-car
              device(OMNI), comprehensive fleet management system, and
              user-friendly app. This trio is designed to streamline your fleet
              management, ensuring safety, efficiency, and transparency.
            </p>

            <div className="desc-section">
              <h3>OMNI Deice: Safety and Efficiency Combined</h3>
              <ul>
                <li>
                  Features Driver Monitoring System (DMS), Advanced Driver
                  Assistance Systems (ADAS), and Digital Video Recording (DVR).
                </li>
                <li>
                  Real-time monitoring of driver behavior and vehicle
                  performance.
                </li>
                <li>
                  Internet-enabled for seamless data transmission to the fleet
                  management system.
                </li>
              </ul>
            </div>

            <div className="desc-section">
              <h3>
                Fleet Management Cloud Service: Smart Monitoring and Analysis
              </h3>
              <ul>
                <li>
                  Track and analyze fleet data for informed decision-making.
                </li>
                <li>Improve safety through DMS and ADAS insights.</li>
                <li>
                  Reduce operational costs by identifying efficiency
                  opportunities.
                </li>
                <li>
                  Easy analysis of reports and analytics for strategic planning.
                </li>
              </ul>
            </div>

            <div className="desc-section">
              <h3>The App: Fleet Management at Your Fingertips</h3>
              <ul>
                <li>
                  Get instant notifications and access to fleet data anytime,
                  anywhere.
                </li>
                <li>
                  Enhance operational transparency and team accountability.
                </li>
              </ul>
            </div>

            <p>
              This integrated solution represents a new era in fleet management,
              combining cutting-edge technology with practical applications.
              Make the smart choice for your fleet&apos;s future today.
            </p>
            <p>
              Begin your journey with our product by exploring the quick guide
              below for an overview of its key features. For detailed
              instructions and comprehensive usage tips, please refer to our
              extensive{' '}
              <a href="https://api.cloud.mindtronicai.com:7001/download/OMNI_User_Manual_v1.0.pdf">
                manual
              </a>
              .
            </p>
          </div>
        </div>
        {/* <h2>Quick Start</h2>
        <h3>Select Your Role</h3>
        <div className="row">
          <a href="/omni-manager" className="green-color-element">
            <h2>Manager</h2>
            <p>
              The manager is the user who can access the fleet management
              system.
            </p>
          </a>
          <a href="/omni-installer" className="blue-color-element">
            <h2>Installater</h2>
            <p>
              The installer successfully mounted the OMNI system in the vehicle.
            </p>
          </a>
        </div> */}
        {/* The table for download. */}
        <h2>Next Steps</h2>
        <p>
          Now that you&apos;re familiar with the features and benefits of our
          product, it&apos;s time to get started with the installation. Our
          detailed <strong>Installation Tutorial</strong> will guide you through
          each step of the process, ensuring a smooth and successful setup.
        </p>
        <p>
          <strong>Ready to begin?</strong> Follow the steps in our&nbsp;
          <a href="/omni/installation">Installation Tutorial</a> to get started.
        </p>
        <ul>
          <li>
            <strong>Step-by-Step Instructions:</strong> Easy-to-follow
            instructions that will take you through the installation process.
          </li>
          <li>
            <strong>Support Resources:</strong> Information on how to get
            further assistance if needed.
          </li>
        </ul>
        <p>
          We are excited to see you start using our product. Click the link
          above to proceed with the installation!
        </p>
        <h2>Resources</h2>
        <DownloadTable documents={documents} />
      </div>
    </div>
  );
}

// Define prop types
OmniOverviewPage.propTypes = {
  user: PropTypes.string.isRequired,
};

export default OmniOverviewPage;
