import React, { useEffect } from 'react';
import './css/ContentPage.css'; // Ensure this is correctly imported

import DownloadTable from './DownloadTable';

const documents = [
  {
    name: 'Quick Installation Guide v1.0',
    description: 'The quick installation guide of OMNI device.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/OMNI_Portal_Quick_installation_guide.pdf',
  },
  {
    name: 'OMNI Installation/Calibration Manual v1.2',
    description: 'The manual for OMNI installation and calibration.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/OMNI_Installation_Calibration_Manual_v1.2.pdf',
  },
  {
    name: 'OMNI User Manual v1.0',
    description: 'The user manual of the OMNI device.',
    downloadUrl:
      'https://api.cloud.mindtronicai.com:7001/download/OMNI_User_Manual_v1.0.pdf',
  },
  // ... more documents
];

function OmniInstallationPage() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="documentPageContainer">
      <div className="centerContent">
        <div className="desc-container">
          <h1>Vehicle Installation</h1>
          <p>
            This quickstart is designed to help the installation. If you are an
            experienced installer, you can just skip this section. Throughout
            this quickstart, you will learn:
          </p>{' '}
          <li>Installation</li>
          <li>Calibration</li>
          <li>Setup Internet Connectivity</li>
          <h2>Step 1: Install the OMNI device</h2>
          <p>
            Before operating the OMNI device, it&apos;s essential to install it
            correctly in the cabin. We recommend consulting the&nbsp;
            <a href="https://api.cloud.mindtronicai.com:7001/download/OMNI_Portal_Quick_installation_guide.pdf">
              Quick Installation Guide
            </a>
            &nbsp; for detailed instructions on setting up the OMNI device.{' '}
          </p>
          <h3>Main Unit</h3>
          <ol>
            <li>
              <strong>Identify the Installation Point</strong>&nbsp; Locate the
              central point of the cockpit on your windshield. This is where the
              OMNI&apos;s ADAS Camera&apos;s position will be installed.
            </li>
            <li>
              <strong>Mounting the OMNI main unit</strong>&nbsp; Attach the OMNI
              device to the identified central point on the windshield. Ensure
              it is facing forwards, towards the front of the vehicle.
            </li>
            <li>
              <strong>Aligning the Camera</strong>&nbsp; Carefully adjust the
              OMNI main unit so that the ADAS Camera is perfectly aligned with
              the center of the cockpit. This alignment is crucial for accurate
              functioning.
            </li>
            <li>
              <strong>Checking for Tilt</strong>&nbsp; Verify that the OMNI
              device is not tilted. Check for both pitch (up and down) and roll
              (side to side) tilts. The device should be perfectly level for
              optimal performance.
            </li>
            <li>
              <strong>Securing the Device</strong>&nbsp; Once properly aligned
              and leveled, secure the OMNI main unit in place, ensuring it
              remains stable and fixed in its position.
            </li>
            <li>
              <strong>Final Verification</strong>&nbsp; Perform a final check to
              confirm that the OMNI main unit is correctly installed, aligned,
              and secured without any tilt.
            </li>
          </ol>
          <h3>DMC</h3>
          <ol>
            <li>
              <strong>Identify the Installation Point</strong>&nbsp; Position
              the DMC directly above the center of the steering wheel. It can be
              placed within 10 cm to the left or right of this central point.
            </li>
            <li>
              <strong>Adjusting the Distance</strong>&nbsp; Ensure that the DMC
              is positioned 55 to 70 cm in front of the driver. This distance is
              crucial for optimal functionality and user comfort.
            </li>
            <li>
              <strong>Securing the Device</strong>&nbsp; Once you have
              positioned the DMC correctly, secure it in place. Ensure it is
              stable and fixed in its position to prevent any movement during
              use.
            </li>
            <li>
              <strong>Final Verification</strong>&nbsp; Perform a final check to
              confirm that the DMC is correctly installed and aligned as per the
              specified measurements.
            </li>
          </ol>
          <h3>Power</h3>
          <ol>
            <li>
              <strong>Connect the Red Wire</strong>&nbsp; Attach the red wire to
              the positive terminal (+) of the battery. This wire is typically
              responsible for the primary power supply.
            </li>
            <li>
              <strong>Connect the Black Wire</strong>&nbsp; Connect the black
              wire to the negative terminal (-) of the battery. This wire is for
              grounding the electrical system.
            </li>
            <li>
              <strong>Connect the Orange Wire</strong>&nbsp; Attach the orange
              wire to the ACC terminal. This connection is used for power
              that&apos;s only available when the ignition is in the accessory
              position.
            </li>
            <li>
              <strong>Connect the USB</strong>&nbsp; Finally, connect the USB
              cable to the OMNI device. This will provide power to the unit.
            </li>
          </ol>
          <h2>Step 2: Calibrate the OMNI device</h2>
          <p>
            Calibration is vital for the optimal functioning of OMNI&apos;s
            features. Improper calibration can negatively affect both the
            Advanced Driver-Assistance Systems (ADAS) and the Driver Monitoring
            Systems (DMS), resulting in a marked rise in false positives and
            negatives. It&apos;s important to follow the&nbsp;
            <a href="https://api.cloud.mindtronicai.com:7001/download/OMNI_Installation_Calibration_Manual_v1.0.pdf">
              Installation and Calbration Manual
            </a>
            &nbsp; closely during the calibration process. If you&apos;re
            unfamiliar with any aspect of it, don&apos;t hesitate to seek
            assistance from a Mindtronic AI expert.{' '}
          </p>
          <h2>Step 3: Setup Internet Connectivity</h2>
          <p>
            Prior to utilizing the OMNI system, it is essential to activate
            internet connectivity to access cloud-based features. Internet
            connection can be established via Wi-Fi or Cellular network. This
            connectivity is crucial for uploading the vehicle&apos;s coordinates
            and for transmitting data from the DMS (Driver Monitoring System),
            ADAS (Advanced Driver Assistance Systems), and driving behavior
            sensors. It also facilitates the uploading of event clips associated
            with these systems. Ensuring a stable internet connection allows the
            OMNI system to function optimally, providing real-time data
            transmission and access to essential cloud-based services.
          </p>
          <p>
            All of the operation in this step is operated in the{' '}
            <strong>Main Unit</strong>.
          </p>
          <h3>By Wi-Fi:</h3>
          <ol>
            <li>
              Go to Main Menu -&gt; Internet -&gt; Select the second row option.
            </li>
            <li>Select the Wi-Fi network you wish to connect to.</li>
            <li>
              Enter the password and press &apos;ENT&apos; to close the keyboard
              view.
            </li>
            <li>Choose &apos;Confirm&apos; to establish the connection.</li>
            <li>
              Wait a few seconds, then a Wi-Fi icon will appear at the top right
              of the display.
            </li>
          </ol>
          <h3>By Cellular:</h3>
          <ol>
            <li>Insert the SIM card.</li>
            <li>
              Navigate to Main Menu -&gt; Internet -&gt; Pin Length, and set the
              length of the PIN code.
            </li>
            <li>
              Proceed to Main Menu -&gt; Internet -&gt; Pin Code, and enter the
              PIN code.
            </li>
            <li>
              After this, a 4G icon should be visible at the top right of the
              display.
            </li>
          </ol>
          <h2>Next Steps</h2>
          <p>
            You have successfully completed the installation. You may now
            proceed to&nbsp;
            <a href="/omni/register">register your OMNI device</a>.
          </p>
          {/* The table for download. */}
          <h2>Resources</h2>
          <DownloadTable documents={documents} />
        </div>
      </div>
    </div>
  );
}

export default OmniInstallationPage;
